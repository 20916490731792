<template>
  <div>
    <audio
      :autoplay="autoplay"
      ref="player-slide"
      :src="currentObj.audio"
      @ended="next()"
      @play="playing = true"
      @pause="playing = false"
      @timeupdate="createSubtitle()"
    ></audio>
    <b-carousel
      class="main-carousel mx-auto"
      ref="custom-carousel"
      v-model="currentIndex"
      :no-wrap="true"
      controls
      :interval="0"
      img-width="100000"
      img-height="10000"
    >
      <b-carousel-slide
        v-for="obj in content"
        :key="obj.id"
        :img-src="obj.image"
        img-alt="slide"
      >
      </b-carousel-slide>
      <b-img
        @click.self="togglePlayer()"
        class="control-icon-style"
        :src="playing ? pauseIcon : playIcon"
      />

      <span class="custom-carousel-indicator"
        >{{ currentIndex + 1 }}/{{ content.length }}</span
      >
      <span class="subtitles"> {{ currentWord }}</span>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: "CustomAudioSlider",
  props: {
    /**
     * An array of the slides that will be shown
     * The structure of the every object is:
     *   title: string, image: {url}, audio: {url}
     */
    content: {
      type: Array,
      required: true,
    },
    width: {
      type: String,
      default: "100",
      required: false,
    },
    autoplay: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      currentIndex: 0,
      playing: false,
      playIcon:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAABQCAYAAABPlrgBAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAF1SURBVHja7NxbbsJADIVhsLzDrHPWVJZSCajUQmguc/Pl91MklAz6dMZv9vX2dSuXRy0X6l7y67nA8Y7yA1NAWa/UOLLxewGF1OxGSYcjJ94JjyMV7xZQEqVGGn0nFI40/l4IHOn03QJKsNTIgDPc4cjAswoojlMjk841jSOTzzeJI0b+RwHFeGrE4JWejmMRZTqOZZRp/cYDyvDUeEEZiuMNZciV8orSNTWeUbrhREBpjhMJpVm/iYhSnZqoKFU40VFO4WRBOdRvsqHsSk1GlE2czCgfcUBZ6TeKxTsMSVkpkvK3FpKyAkJSXjBA+WcWQQCh0e6aVFEw8qGcmmESQHIkpXrCTcGIidJ89lEAiZOUrpOxCobv6zNsblrB8IUybaJewbDfU0zsW1Aw7KGY3MShYNjoKeb3tCgYc1DcbfBRMMb1FNf7nRSMfiihNn8JIG2TEnYvnIJRh5JmY6AAcjwpKfdJKhjbKGwafekpgDzrW4ABAMB/PbhARrnXAAAAAElFTkSuQmCC",
      pauseIcon:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAABQCAYAAABPlrgBAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAHjSURBVHja7NxdcoIwEMDxhSD04xD2TpyTO9VDVAsSKIvi6AMVNmCnzn9fnCEJH79kAy9u1LatELcRQwAKKNZI5g7Yfe5G2w5HKZrGSxw7ed1IPtZv+7GVpa5biyuq6ijSbY1ptukeyOeh10xCVRWiA+jj9OuGpuLc/iuQNb4rX7ykThEkSYcF7/vrdm3SteV/kj6H0l9AxkLbtd/CJj3IWJzbCp2wh6L0IJmb1Ff7LQgz+Tw6IZpeD0EpfTQZ5AbGOHOX/cO3s8cnp5RaHyVztg++e6l29wFdZN73nvKVvC8b8ypz0qy7UmpJwlLAOD5NIvM1000sT7lS+KIFBRRQQAEFFFAIUEABBRRQQAEFFFBAAQUUUEAhQAEFFFBAAQUUUEABBRRQQAEFAlBAAQUUUEABBRRQQAEFFFAIUEABBRRQQPk/KInUQWU/rOOr2l47qjo266+U2ttu0DpO4y2LzZPh5RH/YHfGSg4urAKEFouxxNyiNrE1Bb7KeTeo/UNTz1g9J199TxniPXOTYbSf9l9oH8zXBAl+++iD1t38lyMbmR7X9gVBLg+rtZ7GYl82ZpBTJgSGpkRyLsVxXQZEj2f98XqV1+b1PjGUGBoqd3WbctC5I8ok8vEGCiigrBs/AgwAO8iO8aKxXSIAAAAASUVORK5CYII=",
      currentWord: "",
    };
  },

  methods: {
    next() {
      this.$refs["player-slide"]["pause"]();

      setTimeout(() => {
        this.$refs["custom-carousel"].next();
      }, 1000);
    },

    previous() {
      setTimeout(() => {
        this.$refs["player-slide"]["pause"]();
        this.$refs["custom-carousel"].prev();
      }, 1000);
    },
    togglePlayer() {
      if (this.$refs[`player-slide`] && this.$refs[`player-slide`]["paused"]) {
        this.$refs[`player-slide`]["play"]();
      } else {
        this.$refs[`player-slide`]["pause"]();
      }
    },
    createSubtitle() {
      let currentTime = this.$refs[`player-slide`]["currentTime"];
      console.log(currentTime);
      this.currentSubtitle[0].words.forEach((element) => {
        if (element.start <= currentTime && element.end >= currentTime) {
          this.currentWord = element.word;
        }
      });
    },
  },
  computed: {
    limitedIndex() {
      return Math.abs(this.currentIndex) % this.content.length;
    },
    currentObj() {
      return this.content[this.limitedIndex];
    },
    currentSubtitle() {
      return this.currentObj.subtitles;
    },
  },
};
</script>

<style>
@media screen and (min-width: 501px) {
  .carousel-control-prev-icon {
    height: 40px !important;
    width: 40px !important;
    opacity: 0.2 !important;
  }
  .carousel-control-next-icon {
    height: 40px !important;
    width: 40px !important;
    opacity: 0.2 !important;
  }
  .control-icon-style {
    position: sticky;
    height: 40px;
    width: 40px;
    cursor: pointer;
    left: calc(50% - 10px);
    top: calc(50% - 10px);
    opacity: 0.2;
  }
  .control-icon-style:hover {
    opacity: 1;
  }
  .custom-carousel-indicator {
    position: absolute;
    color: #9aacb2;
    right: 3%;
    bottom: 1%;
    font-size: 2.5em;
  }
  .main-carousel {
    width: 80%;
    height: auto;
  }
  .subtitles {
    position: absolute;
    color: #fdfeff;
    right: 10%;
    bottom: 2%;
    left: 1%;
    /* background-color: rgb(104, 101, 101); */
    font-size: 2.5em;
    width: 80%;
  }
}

@media screen and (max-width: 500px) {
  .carousel-control-prev-icon {
    height: 30px !important;
    width: 30px !important;
    opacity: 0.2 !important;
  }
  .carousel-control-next-icon {
    height: 30px !important;
    width: 30px !important;
    opacity: 0.2 !important;
  }
  .control-icon-style {
    position: sticky;
    height: 20px;
    width: 20px;
    cursor: pointer;
    left: calc(50% - 5px);
    top: calc(50% - 5px);
    opacity: 0.2;
  }
  .control-icon-style:hover {
    opacity: 1;
  }
  .custom-carousel-indicator {
    position: absolute;
    color: #9aacb2;
    right: 3%;
    bottom: 1%;
    font-size: 1.5em;
  }
  .subtitles {
    position: absolute;
    color: #9aacb2;
    right: 50%;
    bottom: 2%;
    font-size: 2em;
  }
}
</style>
